import createPermissionsForUser from './access/createPermissionsForUser'
import deletePermissionsForUser from './access/deletePermissionsForUser'
import getAllUserPermissions from './access/getAllUserPermissions'
import getUserPermissions from './access/getUserPermissions'
import updatePermissionsForUser from './access/updatePermissionsForUser'
import acceptAccount from './accounts/acceptAccount'
import rejectAccount from './accounts/rejectAccount'
import editAccount from './accounts/editAccount'
import createNewAccount from './accounts/createNewAccount'
import getAccounts from './accounts/getAccounts'
import getSingleAccount from './accounts/getSingleAccount'

import acceptgcpProject from './gcpProjects/acceptgcpProject'
import rejectgcpProject from './gcpProjects/rejectgcpProject'
import editgcpProject from './gcpProjects/editgcpProject'
import createNewgcpProject from './gcpProjects/createNewgcpProject'
import getgcpProjects from './gcpProjects/getgcpProject'
import getSinglegcpProject from './gcpProjects/getSinglegcpProject'

import getMicroservices from './microservices/getMicroservices'
import createNewMicroservice from './microservices/createNewMicroservice'
import editMicroservice from './microservices/editMicroservice'
import rejectMicroservice from './microservices/rejectMicroservice'
import acceptMicroservice from './microservices/acceptMicroservice'
import getSingleMicroservice from './microservices/getSingleMicroservice'

import acceptProject from './projects/acceptProject'
import createNewProject from './projects/createNewProject'
import editProject from './projects/editProject'
import getProjects from './projects/getProjects'
import getSingleProject from './projects/getSingleProject'
import rejectProject from './projects/rejectProject'

import createTags from './tags/createTags'
import getTagsById from './tags/getTagsById'
import updateMultiStepTagsById from './tags/updateMultiStepTagsById'
import approveTagsById from './tags/approveTagsById'
import updateTagsById from './tags/updateTagsById'
import getTagsByAccountId from './tags/getTagsByAccountId'

import getPlatforms from './platforms/getPlatforms'
import createNewPlatform from './platforms/createNewPlatform'
import editPlatform from './platforms/editPlatform'
import rejectPlatform from './platforms/rejectPlatform'
import acceptPlatform from './platforms/acceptPlatform'
import getSinglePlatform from './platforms/getSinglePlatform'

import getApplications from './applications/getApplication'
import getSingleApplication from './applications/getSingleApplication'
import rejectApplication from './applications/rejectApplication'
import editApplication from './applications/editApplication'
import createNewApplication from './applications/createNewApplication'
import acceptApplication from './applications/acceptApplication'
import getTagsByGCPProjectId from './tags/getTagsByGCPProjectId'
import getSingleRhocpTenant from './rhocpTenants/getSingleRhocpTenant'
import createNewRhocpTenant from './rhocpTenants/createNewRhocpTenant'
import acceptRhocpTenant from './rhocpTenants/acceptRhocpTenant'
import rejectRhocpTenant from './rhocpTenants/rejectRhocpTenant'
import getRhocpTenants from './rhocpTenants/getRhocpTenants'

import createNewResource from './projects/resources/createNewResource'
import getProjectResources from './projects/resources/getProjectResources'
import getSingleProjectResource from './projects/resources/getSingleProjectResource'
import approveResource from './projects/resources/approveResource'
import rejectResource from './projects/resources/rejectResource'
import updateApprovers from './projects/resources/updateApprovers'

const api = {
	projects: {
		getProjects,
		getSingleProject,
		createNewProject,
		rejectProject,
		acceptProject,
		editProject,
		resources: {
			createNewResource,
			getProjectResources,
			getSingleProjectResource,
			approveResource,
			rejectResource,
			updateApprovers
		}
	},
	accessManagement: {
		getUserPermissions,
		getAllUserPermissions,
		createPermissionsForUser,
		updatePermissionsForUser,
		deletePermissionsForUser
	},
	accounts: {
		getAccounts,
		getSingleAccount,
		createNewAccount,
		acceptAccount,
		rejectAccount,
		editAccount
	},
	tags: {
		getTagsById,
		createTags,
		updateMultiStepTagsById,
		approveTagsById,
		updateTagsById,
		getTagsByAccountId,
		getTagsByGCPProjectId
	},
	platforms: {
		getPlatforms,
		getSinglePlatform,
		createNewPlatform,
		editPlatform,
		acceptPlatform,
		rejectPlatform
	},
	applications: {
		getApplications,
		getSingleApplication,
		createNewApplication,
		editApplication,
		acceptApplication,
		rejectApplication
	},
	microservices: {
		getMicroservices,
		createNewMicroservice,
		getSingleMicroservice,
		acceptMicroservice,
		rejectMicroservice,
		editMicroservice
	},
	gcpProjects: {
		getgcpProjects,
		getSinglegcpProject,
		createNewgcpProject,
		acceptgcpProject,
		rejectgcpProject,
		editgcpProject
	},
	rhocpTenants: {
		getSingleRhocpTenant,
		getRhocpTenants,
		createNewRhocpTenant,
		acceptRhocpTenant,
		rejectRhocpTenant
	}
}

export default api
