import {
	Box,
	Button,
	Container,
	Form,
	FormField,
	Header,
	Input,
	SpaceBetween
} from '@cloudscape-design/components'
import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'

import type { EphemeralRequest } from '../../../types/projects'
import { useEphemeralFormStore } from '../../../stores/projects/ephemeralFormStore'
import useCreateNewProject from '../../../hooks/mutate/useCreateNewProject'
import FormSelect from '../../../components/ControlledFormInputs/Select'

const ENVIRONMENT_LIST = [
	{ label: 'dv', value: 'dv' },
	{ label: 'st', value: 'st' },
	{ label: 'pd', value: 'pd' },
]

const NewEphemeralForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useEphemeralFormStore()

	const { mutate: createNewProject, isLoading } = useCreateNewProject()

	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return
		const ephemeral: EphemeralRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			isephemeral: true,
			creationTime: new Date().toISOString()
		}
		createNewProject(ephemeral, {
			onSuccess: () => {
				navigate('/projects')
				clearFormValues()
			}
		})
	}

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Request a New Ephemeral Project
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<FormInput
							name="project"
							label="Project Name"
							type="text"
							control={control}
							rules={{
								required: 'Please enter a valid Project Name',
								validate: value => value.startsWith('atlas-project-') || 'Project Name should start with "atlas-project-"'
							}}
							placeholder="atlas-project-<project_name>"
							setFormValues={setFormValues}
						/>
						<FormInput
							name="workload"
							label="Workload"
							type="text"
							control={control}
							rules={{
								required: 'Please enter a valid Project Name',
								validate: value => value.startsWith('atlas-') || 'Project Name should start with "atlas-"'
							}}
							placeholder="atlas-<workload_name>"
							setFormValues={setFormValues}
						/>
						<FormSelect
							label="Environment"
							name="environment"
							control={control}
							rules={{
								required: 'Please Select Option'
							}}
							setFormValues={setFormValues}
							options={ENVIRONMENT_LIST}
							placeholder="Select Environment"
						/>
						<FormInput
							name="awsAccountID"
							label="AWS Account ID"
							placeholder='Enter AWS Account ID'
							type="number"
							control={control}
							rules={{
								required: 'Please enter your AWS Account ID',
								minLength: {
									value: 12,
									message: 'Minimum length should be 12'
								},
								maxLength: {
									value: 12,
									message: 'Maximum length should be 12'
								}
							}}
							setFormValues={setFormValues}
						/>
						<FormInput
							name="networkID"
							label="Requestor Network ID"
							type="text"
							control={control}
							rules={{
								required: 'Please enter a valid Network ID',
								minLength: {
									value: 5,
									message: 'Minimum length should be 5'
								},
								maxLength: {
									value: 8,
									message: 'Maximum length should be 8'
								},
								pattern: {
									value: /^[a-zA-Z0-9]{5,8}$/,
									message: 'Network ID must be 5-8 alphanumeric characters'
								}
							}}
							placeholder="Enter 5-8 alphanumeric characters"
							setFormValues={setFormValues}
						/>
						<FormInput
							name="customProjectID"
							label="Project ID"
							description="12 alphanumeric characters only"
							type="text"
							rules={{
								required: 'Please enter valid project ID',
								pattern: {
									value: /^[a-zA-Z0-9_]*$/,
									message: 'Please enter valid project ID'
								},
								minLength: {
									value: 12,
									message: 'ProjectID should be 12 letters'
								},
								maxLength: {
									value: 12,
									message: 'ProjectID should be 12 letters'
								}
							}}
							control={control}
							setFormValues={setFormValues}
							placeholder=""
						/>
						<FormInput
							name="teardown_after_days"
							label="Teardown After Days"
							type="text"
							control={control}
							rules={{
								required: 'Please enter a valid no.of Days',
								pattern: {
									value: /^\d{1,2}$/,
									message: 'Minimum 1 Day Required'
								},
								maxLength: {
									value: 2,
									message: 'Maximum 99 Days Allowed'
								}
							}}
							placeholder="Enter Valid No.of Days"
							setFormValues={setFormValues}
						/>
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewEphemeralForm
